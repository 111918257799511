/*
 * @Author: your name
 * @Date: 2020-12-25 19:22:45
 * @LastEditTime: 2021-01-15 13:50:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import { title } from "../utils/config";

function checkLogin(next) {
    const { loginFlag } = localStorage;
    loginFlag ? next() : next({ name: 'Login' });
}

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/home/Home'),
        redirect: '/index',
        children: [{
                path: '/index',
                name: 'HomeIndex', //首页
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/home/Index'),
            },
            {
                path: '/topic',
                name: 'Topic', //专题页
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/home/Topic'),
            },
            {
                path: '/coupon',
                name: 'CouponCenter', //领劵中心
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/home/couponCenter'),
            }
        ],
    }, {
        path: '/goods',
        name: 'Goods', //商品模块
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/goods/Header'),
        children: [{
                path: 'category',
                name: 'GoodsCategory', //商品分类页面
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/goods/Category'),
            },
            {
                path: 'list',
                name: 'GoodsList',
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/goods/GoodsList'),
            },
            {
                path: '/brand',
                name: 'Brand', //品牌中心
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/goods/Brand')
            },
            {
                path: '/zixunxq',
                name: 'zixunxq', //文章列表
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/goods/zixunxq')
            },
        ]
    },
    {
        path: '/goods',
        name: 'GoodsDetail', //商品详情页头部
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/goods/GoodsDetailHeader'),
        redirect: '/goods/detail',
        children: [{
            path: 'detail',
            name: 'GoodsDetail', //商品详情页
            component: () =>
                import ( /* webpackChunkName: "home" */ '../views/goods/GoodsDetail'),
        }, ]
    },
    {
        path: '/member',
        name: 'Member',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/Common'),
        redirect: '/member/index',
        children: [{
                path: '/member/index',
                name: 'MemberIndex', //个人中心
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/Index'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/collect',
                name: 'MemberCollect', //我的收藏
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/Collect'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/footprint',
                name: 'MemberFootprint', //我的足迹
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/Footprint'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/address/list',
                name: 'memberAddressList', //收货地址列表
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/AddressList'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/order/list',
                name: 'memberOrderList', //我的订单
                component: () =>
                    import ('../views/member/order/List'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/order/refund/apply', //申请退款
                name: 'ApplyRefund',
                component: () =>
                    import ('../views/member/order/ApplyRefund'),
                beforeEnter(to, from, next) { checkLogin(next); }
            }, {
                path: '/member/order/refund/detail', //售后详情
                name: 'RefundDetail',
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/order/RefundDetail'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/order/evaluation',
                name: 'MemberOrderEvaluation', //评价订单列表
                component: () =>
                    import ('../views/member/order/Evaluation'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/order/aftersales', //售后列表
                name: 'AfterSalesList',
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/order/AfterSalesList'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/order/logistics', //填写物流信息
                name: 'Logistics',
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/order/Logistics'),
                beforeEnter(to, from, next) { checkLogin(next); }
            }, {
                path: '/member/coupon',
                name: 'MemberCoupon', //我的优惠卷
                component: () =>
                    import ('../views/member/money/Coupon'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/balance',
                name: 'MemberBalance', //我的余额
                component: () =>
                    import ('../views/member/money/Balance'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/account',
                name: 'MemberAccount', //账号安全
                component: () =>
                    import ('../views/member/center/Account'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/phone',
                name: 'MemberPhoneMange', //手机号管理
                component: () =>
                    import ('../views/member/center/PhoneMange'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/email',
                name: 'memberEmailMange', //邮箱管理
                component: () =>
                    import ('../views/member/center/EmailMange'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/pwd/login',
                name: 'MemberLoginPassword', //登陆密码
                component: () =>
                    import ('../views/member/center/LoginPassword'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/pwd/pay',
                name: 'MemberPayPassword', //支付密码
                component: () =>
                    import ('../views/member/center/PayPassword'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/pwd/reset',
                name: 'MemberResetPassword', //重置支付密码
                component: () =>
                    import ('../views/member/center/ResetPassword'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/info',
                name: 'MemberInfo', //会员信息
                component: () =>
                    import ('../views/member/Info'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/recharge',
                name: 'MemberRecharge', //充值
                component: () =>
                    import ('../views/member/money/Recharge'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/myPoint',
                name: 'MyPoint',
                component: () =>
                    import ('../views/member/myPoint'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/pointOrder/list',
                name: 'pointOrderList',
                component: () =>
                    import ('../views/member/pointOrder/list'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/ApplicationList/ApplicationList',
                name: 'ApplicationList',
                component: () =>
                    import ('../views/member/ApplicationList/ApplicationList'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
        ]
    },
    {
        path: '/msg',
        name: 'Msg',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/msg/Header'), //消息中心公共部分
        redirect: '/msg/list',
        children: [{
                path: 'list',
                name: 'MsgList', //消息列表
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/msg/List'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: 'setting',
                name: 'MsgSetting', //消息接收设置
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/msg/Setting'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
        ]
    },
    {
        path: '/member/order/detail',
        name: 'OrderDetail', //订单详情
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/Header'),
        redirect: '/member/order/detail',
        children: [{
                path: '/member/order/detail',
                name: 'memberOrderDetail', //订单详情
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/order/detail'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: '/member/order/articleList',
                name: 'articleList', //订单详情
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/member/order/articleList'),
                beforeEnter(to, from, next) { checkLogin(next); }
            }
        ]
    },

    {
        path: '/member/order/evaluate',
        name: 'OrderEvaluate', //订单评价
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/Header'),
        redirect: '/member/order/evaluate',
        children: [{
            path: '/member/order/evaluate',
            name: 'memberOrderEvaluate', //订单评价
            component: () =>
                import ( /* webpackChunkName: "home" */ '../views/member/order/Evaluate'),
            beforeEnter(to, from, next) { checkLogin(next); }
        }, ]
    },
    {
        path: '/login',
        name: 'Login', //用户登录页面
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/login/Index'),
    },
    {
        path: '/pwd/forget',
        name: 'ForgetPassword', //用户找回密码页面
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/login/ForgetPassword'),
    },
    {
        path: '/register',
        name: 'register', //用户注册页面
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/login/Register'),
    },
    {
        path: '/agreement',
        name: 'agreement', //用户注册协议页面
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/login/Agreement'),
    },

    {
        path: '/buy',
        name: 'Buy', //下单页入口
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/buy/Header'),
        children: [{
                path: 'confirm',
                name: 'BuyConfirmOrder', //下单页面
                component: () =>
                    import ('../views/buy/ConfirmOrder'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
            {
                path: 'pay',
                name: 'Pay', //支付页面
                component: () =>
                    import ('../views/buy/pay'),
                beforeEnter(to, from, next) { checkLogin(next); }
            },
        ]
    },
    {
        path: '/article',
        name: 'article', //文章页面
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/article/article'),
    },
    {
        path: '/store',
        name: 'store',
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/store/Header'),
        children: [{
            path: 'list',
            name: 'StoreList', //店铺列表模块
            component: () =>
                import ( /* webpackChunkName: "home" */ '../views/store/storeList'),
        }]
    }, {
        path: '/store/detail',
        name: 'StoreDetail', //店铺模块
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/store/StoreHeader'),
        redirect: '/store/index',
        children: [{
                path: '/store/index',
                name: 'storeIndex', //店铺首页
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/store/Index'),
            },
            {
                path: '/store/goods',
                name: 'storeGoods', //店铺商品列表
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/store/GoodsList'),
            }
        ]
    }, {
        path: '/cart',
        name: 'Cart', //商品模块
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/cart/Header'),
        children: [{
            path: 'index',
            name: 'CartIndex', //购物车页面
            component: () =>
                import ( /* webpackChunkName: "home" */ '../views/cart/CartIndex'),
        }, ]
    },
    {
        path: '/point',
        name: 'Point', //积分商城
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/point/pointIndex/Header'),
        redirect: '/point/index',
        children: [{
                path: 'index',
                name: 'PointIndex', //积分首页
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/point/pointIndex/Index'),
            },
            {
                path: 'list',
                name: 'PointList', //积分商品列表
                component: () =>
                    import ( /* webpackChunkName: "home" */ '../views/point/pointIndex/List'),
            }
        ]
    },
    {
        path: '/point/goods',
        name: 'PointDetail', //积分商品详情
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/point/goods/Header'),
        redirect: '/point/detail',
        children: [{
            path: '/point/detail',
            name: 'detail', //积分商品详情
            component: () =>
                import ( /* webpackChunkName: "home" */ '../views/point/goods/Detail'),
        }]
    },
    {
        path: '/point/order/confirm',
        name: 'PointOrder', //积分确认订单
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/point/exchange/ConfirmOrder'),
        beforeEnter(to, from, next) { checkLogin(next); }
    },
    {
        path: '/point/pay',
        name: 'PointPay', //积分支付页面
        component: () =>
            import ('../views/point/exchange/pay'),
        beforeEnter(to, from, next) { checkLogin(next); }
    },
    {
        path: '/member/pointOrder/detail',
        name: 'PointOrderPay', //积分订单详情
        component: () =>
            import ( /* webpackChunkName: "home" */ '../views/member/Header'),
        redirect: '/member/pointOrder/detail',
        children: [{
            path: '/member/pointOrder/detail',
            name: 'memberPointOrderPay', //积分订单详情
            component: () =>
                import ( /* webpackChunkName: "home" */ '../views/member/pointOrder/detail'),
            beforeEnter(to, from, next) { checkLogin(next); }
        }, ]
    },
    {
        path: '/service',
        name: 'serviceChat',
        component: () =>
            import ('../views/service/chatPage'),
        beforeEnter: (to, from, next) => { checkLogin(next); }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    next()
})

router.afterEach(() => {
    window.document.title = title;
})

export default router