/*
 * @Author: your name
 * @Date: 2022-02-10 21:22:57
 * @LastEditTime: 2022-04-29 10:22:30
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /PC/src/utils/config.js
 */
/**
 *  项目的配置文件
 */
// export const apiUrl = 'http://yichenglypc.slodon.cn/'; //接口请求地址
export const apiUrl = 'https://longyurenzheng.com/'; //接口请求地址
// export const apiUrl = 'http://127.0.0.1:8005/'; //接口请求地址
// export const apiUrl = 'http://pc1.ilongyu.com.cn/'; //接口请求地址
// export const apiUrl = 'https://mall.newlink.com/'; //接口请求地址
export const curLang = 'zh'; //当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
// export const chatUrl = 'wss://im-mall.newlink.com'; //客服地址
export const chatUrl = 'wss://im.longyurenzheng.com'; //客服地址
// export const title = '龙域认证-全国移动互联网认证服务平台'; //浏览器顶部title
// export const title = '龙域认证|全国移动互联网认证服务平台|CCRC、安防、双软认证、cisp、cmmi、CCC、安全集成、安全运维、SRRC认证、iso9001、iso27001、iso9001质量管理体系、iso9000质量管理体系、ISO、it认证、互联网认证、认证机构、企业认证、3c认证、体系认证、国际认证、知识产权、专利、专利申请、商标、公安部销许、测评、电子产品认证、系统集成、系统集成资质、产品认证、资质证书、资质代办、工业控制、软件企业认定、软件著作权、QMS、食品安全管理体系、食品安全管理体系认证'; //浏览器顶部title
export const title = '北京一城龙域科技有限公司|龙域认证|管理体系|QMS质量|EMS环境|FSMS食品安全|ISMS信息安全|ITSMS信息技术服务|EnMS能源|HACCP|50430建工质量|OHSMS职业健康|自愿性产品|强制性产品|有机产品|CCC产品认证'; //浏览器顶部title

// ** copyright *** slodon *** version-v2.9.2 *** date-2021-06-18 ***主版本v2.9