/*
* 语言文件 zh：中文
* */
export const lang_zn = {
    //首页公共头部最顶部
    '您好，欢迎来到': '您好，欢迎来到',
    '退出': '退出',
    '注册': '注册',
    '商城首页': '商城首页',
    '待支付订单': '待支付订单',
    '待收货订单': '待收货订单',
    '待评价订单': '待评价订单',
    '个人中心': '个人中心',
    '商品收藏': '商品收藏',
    '店铺收藏': '店铺收藏',
    '我的账户': '我的账户',
    '服务中心': '服务中心',

    //登录页面
    '手机登录': '手机登录',
    '账号登录': '账号登录',
    '还没注册？': '还没注册？',
    '去注册': '去注册',
    '账号为4~16位字母、数字或下划线': '账号为4~16位字母、数字或下划线',
    '登录': '登录',
    '请输入密码': '请输入密码',
    '立即注册': '立即注册',
    '服务商入驻': '服务商入驻',
    '用户注册': '用户注册',
    '商家注册入口': '商家注册入口',
    '忘记密码': '忘记密码',
    '请输入账号/手机号': '请输入账号/手机号',
    '请输入手机号': '请输入手机号',
    '请输入验证码': '请输入验证码',
    '获取验证码': '获取验证码',
    's后获取': 's后获取',
    '请输入正确的账号/手机号': '请输入正确的账号/手机号',

    //忘记密码页面
    '想起密码？': '想起密码？',
    '去登录': '去登录',
    '找回密码': '找回密码',

    //注册页面
    '已有账号？': '已有账号？',
    '已有账号，去登录': '已有账号，去登录',
    '注册账号': '注册账号',
    '请输入图形验证码': '请输入图形验证码',

    //注册协议页面
    '我已知悉？': '我已知悉？',

    //文章，商品分类
    '暂无数据': '暂无数据',
    '首页': '首页',
    '文章': '文章',
    '文章分类': '文章分类',
    '最新文章': '最新文章',
    '搜索': '搜索',
    '默认': '默认',
    '人气': '人气',
    '成交量': '成交量',
    '浏览量': '浏览量',
    '请输入店铺名称': '请输入店铺名称',

    //商品列表
    '综合': '综合',
    '评论数': '评论数',
    '商品类型': '商品类型',
    '仅显示有货': '仅显示有货',
    '仅平台自营': '仅平台自营',
    '分类': '分类',
    '筛选结果': '筛选结果',
    '包含分类': '包含分类',
    '清空': '清空',
    '更多选项': '更多选项',

    //商品详情页面
    '自营': '自营',
    '联系客服': '联系客服',
    '已关注': '已关注',
    '关注店铺': '关注店铺',
    '价格': '价格',
    '销量': '销量',
    '优惠券': '优惠券',
    '促销': '促销',
    '查看详情': '查看详情',
    '配送至': '配送至',
    '请选择地址': '请选择地址',
    '运费': '运费',
    '数量': '数量',
    '库存': '库存',
    '立即购买': '立即购买',
    '购物车': '购物车',
    '已收藏': '已收藏',
    '收藏': '收藏',
    '分享': '分享',
    '看了又看': '看了又看',
    '店铺等级': '店铺等级',
    '商品评价': '商品评价',
    '物流评价': '物流评价',
    '售后服务': '售后服务',
    '进入店铺': '进入店铺',
    '店铺推荐': '店铺推荐',
    '高': '高',
    '低': '低',
    '中': '中',
    '取消关注': '取消关注',
    '热门收藏': '热门收藏',
    '已售': '已售',
    '浏览': '浏览',
    '商品详情': '商品详情',
    '评价': '评价',
    '商品服务': '商品服务',
    '店铺热销': '店铺热销',
    '手机下单': '手机下单',
    '加入购物车': '加入购物车',
    '品牌': '品牌',
    '查看全部': '查看全部',
    '收起全部': '收起全部',
    '评分': '评分',
    '好评率': '好评率',
    '全部': '全部',
    '有图': '有图',
    '好评': '好评',
    '中评': '中评',
    '差评': '差评',
    '商品已下架': '商品已下架',
    '库存不足': '库存不足',
    '该商品暂无评论~': '该商品暂无评论~',
    '暂无相关商品': '暂无相关商品',
    '库存不足！': '库存不足！',
    '超过购买上限!': '超过购买上限!',
    '未登录，请先登录!': '未登录，请先登录!',
    '加入购物车成功': '加入购物车成功',
    '暂无商品服务~': '暂无商品服务~',
    '该商品暂无详情~': '该商品暂无详情~',
    'QQ': 'QQ',
    '新浪': '新浪',
    '微信': '微信',
    '分享到微信': '分享到微信',


    //购物车页面
    '全选': '全选',
    '商品信息': '商品信息',
    '单价': '单价',
    '小计（元）': '小计（元）',
    '操作': '操作',
    '领取优惠券': '领取优惠券',
    '满减': '满减',
    '去凑单': '去凑单',
    '再逛逛': '再逛逛',
    '移入收藏夹': '移入收藏夹',
    '删除': '删除',
    '失效商品': '失效商品',
    '失效': '失效',
    '已下架': '已下架',
    '删除选中的商品': '删除选中的商品',
    '清空失效的商品': '清空失效的商品',
    '已选择': '已选择',
    '件商品': '件商品',
    '合计': '合计',
    '促销减': '促销减',
    '去结算': '去结算',
    '确定清空失效商品？': '确定清空失效商品？',
    '取 消': '取 消',
    '确 定': '确 定',
    '确定删除选中商品？': '确定删除选中商品？',
    '剩余': "剩余",
    '件': '件',
    '确定': '确定',
    '取消': '取消',
    '最低限购1件!': '最低限购1件!',
    '数量超出购买范围！': '数量超出购买范围！',
    '超过购买限制!': '超过购买限制!',
    '不能输入非0数字!': '不能输入非0数字!',
    '移入收藏夹成功！': '移入收藏夹成功！',
    '删除成功！': '删除成功！',
    '清空失效商品成功！': '清空失效商品成功！',
    '结算商品不能为空！': '结算商品不能为空！',

    //我的收藏
    '我的': '我的',
    '收藏的商品': '收藏的商品',
    '收藏的店铺': '收藏的店铺',
    '热门推荐': '热门推荐',
    '这里空空如也，快去首页逛逛吧~': '这里空空如也，快去首页逛逛吧~',
    '去首页 > >': '去首页 > >',
    '已加入购物车': '已加入购物车',
    '普通关注': '普通关注',
    '特别关注': '特别关注',
    ' 设为特殊关注': ' 设为特殊关注',
    '取消特殊关注': '取消特殊关注',
    '本月上新': '本月上新',
    '热销推荐': '热销推荐',
    '取消收藏': '取消收藏',
    '管理': '管理',
    '设为特殊关注': '设为特殊关注',
    '您还没关注过店铺，快去': '您还没关注过店铺，快去',
    '逛逛吧~': '逛逛吧~',
    '您还没关注过商品，快去': '您还没关注过商品，快去',


    //首页公共头部——搜索模块
    '请输入关键词': '请输入关键词',
    '热门搜索': '热门搜索',
    '我的购物车': '我的购物车',
    '购物车中还没有商品，赶紧选购吧': '购物车中还没有商品，赶紧选购吧',

    //店铺公共头部数据——导航和店铺分类
    '综合评分': '综合评分',
    '店铺评分': '店铺评分',
    '描述相符': '描述相符',
    '服务态度': '服务态度',
    '服务承诺': '服务承诺',
    '正品保障': '正品保障',
    '发货速度': '发货速度',
    '客服电话': '客服电话',
    '店铺首页': '店铺首页',
    '搜本店': '搜本店',
    '本店全部分类': '本店全部分类',
    '所有商品': '所有商品',
    '请输入...': '请输入...',

    //店铺商品列表
    '件相关商品': '件相关商品',
    '共': '共',

    //首页装修
    '人购买': '人购买',
    '立即抢购': '立即抢购',
    '查看更多': '查看更多',
    '此处添加商品': '此处添加商品',
    '此处添加【360*560】图片': '此处添加【360*560】图片',
    '请选择商品，数量建议选择3的倍数，最少3个，最多12个': '请选择商品，数量建议选择3的倍数，最少3个，最多12个',
    '请上传500*80的图片': '请上传500*80的图片',
    '平台还未设置公告': '平台还未设置公告',
    '平台还未设置资讯': '平台还未设置资讯',
    '该场景还没有商品': '该场景还没有商品',
    '请选择供货商，数量建议选择5的倍数，最少5个，最多20个': '请选择供货商，数量建议选择5的倍数，最少5个，最多20个',

    //我的足迹
    '关注': '关注',

    //个人中心
    '会员收货地址': '会员收货地址',
    '我的财产': '我的财产',
    '余额': '余额',
    '充值': '充值',
    '领券': '领券',
    '查看': '查看',
    '售后/退货': '售后/退货',
    '进行中的订单': '进行中的订单',
    '去支付': '去支付',
    '我的关注': '我的关注',
    '商品关注': '商品关注',
    '店铺关注': '店铺关注',
    '积分': '积分',
    '这里空空如也，快去挑选合适的商品吧~': '这里空空如也，快去挑选合适的商品吧~',


    //订单列表
    '订单': '订单',
    '商品名称/订单编号': '商品名称/订单编号',
    '我的订单': '我的订单',
    '全部订单': '全部订单',
    '待支付': '待支付',
    '待发货': '待发货',
    '待收货': '待收货',
    '已完成': '已完成',
    '待评价': '待评价',
    '请输入内容': '请输入内容',
    '搜索订单': '搜索订单',
    '售后维权': '售后维权',
    '订单金额': '订单金额',
    '下单时间': '下单时间',
    '订单号': '订单号',
    '订单详情': '订单详情',
    '普通订单': '普通订单',
    '修改地址': '修改地址',
    '查看物流': '查看物流',
    '确认收货': '确认收货',
    '确认收货?': '确认收货?',
    '取消订单': '取消订单',
    '立即支付': '立即支付',
    '删除订单': '删除订单',
    '取消订单理由': '取消订单理由',
    '物流信息': '物流信息',
    '运单号': '运单号',
    '承运人': '承运人',
    '联系人': '联系人',
    '联系电话': '联系电话',
    '更换地址': '更换地址',
    '收货人': '收货人',
    '联系方式': '联系方式',
    '收货地址': '收货地址',
    '提交修改': '提交修改',
    '切换地址成功': '切换地址成功',
    '确认删除该订单?': '确认删除该订单?',
    '提示': '提示',
    '删除订单成功': '删除订单成功',
    '请选择取消理由': '请选择取消理由',
    '取消订单成功': '取消订单成功',
    '确认收货成功': '确认收货成功',
    '暂无订单～': '暂无订单～',

    // 售后详情页面
    '退款退货单号': '退款退货单号',
    '提交申请': '提交申请',
    '审核中': '审核中',
    '完成': '完成',
    '退款金额': '退款金额',
    '问题描述': '问题描述',
    '时间': '时间',
    '平台审核备注': '平台审核备注',
    '退款凭证': '退款凭证',
    '退款单号': '退款单号',

    // 申请售后

    '添加/编辑': '添加/编辑',
    '（同订单商品可批量申请）': '（同订单商品可批量申请）',
    '退款金额可修改，最多': '退款金额可修改，最多',
    '退款金额不可修改，最多': '退款金额不可修改，最多',
    '含运费': '含运费',
    '不含运费': '不含运费',
    '修改金额': '修改金额',
    '申请件数': '申请件数',
    '请输入申请件数': '请输入申请件数',
    '申请类型': '申请类型',
    '货物状态': '货物状态',
    '退款原因': '退款原因',
    '请选择退款原因': '请选择退款原因',
    '请输入退款金额': '请输入退款金额',
    '请输入问题描述(选填)': '请输入问题描述(选填)',
    '批量售后商品选择': '批量售后商品选择',
    '上传凭证': '上传凭证',
    '未收到货': '未收到货',
    '已收到货': '已收到货',
    '退款退货': '退款退货',
    '不可超出最大申请数量': '不可超出最大申请数量',
    '不可超过最大退款金额！': '不可超过最大退款金额！',
    '退款金额不可为负值！': '退款金额不可为负值！',
    '最多上传5张！': '最多上传5张！',


    // 售后列表
    '商品明细': '商品明细',
    '申请时间': '申请时间',
    '状态': '状态',
    '发货': '发货',
    '仅退款': '仅退款',
    '退货退款': '退货退款',

    // 填写物流页面
    '用户发货': '用户发货',
    '商品名称': '商品名称',
    '购买数量': '购买数量',
    '服务类型': '服务类型',
    '快递公司': '快递公司',
    '请选择快递公司': '请选择快递公司',
    '快递单号': '快递单号',
    '请输入快递单号': '请输入快递单号',
    '提交': '提交',
    '请选择物流公司!': '请选择物流公司!',
    '请输入物流单号！': '请输入物流单号！',
    '请输入正确的物流单号！': '请输入正确的物流单号！',
    '请选择物流公司': '请选择物流公司',
    '物流单号': '物流单号',
    '请输入物流单号': '请输入物流单号',

    //消息中心——左侧公共菜单
    '消息列表': '消息列表',
    '系统消息': '系统消息',
    '订单消息': '订单消息',
    '资产消息': '资产消息',
    '售后消息': '售后消息',
    '接收设置': '接收设置',


    //待评订单
    '待评订单': '待评订单',
    '发评价，得积分，积少成多换商品': '发评价，得积分，积少成多换商品',
    '评价小贴士': '评价小贴士',
    '发表评价可获得会员积分': '发表评价可获得会员积分',
    '支付金额': '支付金额',
    '评价订单': '评价订单',

    //订单详情
    '提交订单': '提交订单',
    '支付订单': '支付订单',
    '商家发货': '商家发货',
    '送货方式': '送货方式',
    '物流公司': '物流公司',
    '运单号码': '运单号码',
    '暂无物流信息': '暂无物流信息',
    '订单信息': '订单信息',
    '发票': '发票',
    '不需要发票': '不需要发票',
    '发票抬头': '发票抬头',
    '卖家': '卖家',
    '收货人信息': '收货人信息',
    '商品合计': '商品合计',
    '优惠': '优惠',
    '运费金额': '运费金额',
    '实际金额': '实际金额',
    '交易关闭': '交易关闭',

    //领劵中心
    '领劵中心': '领劵中心',
    '折': '折',
    '立即领取': '立即领取',
    '已领取': '已领取',
    '已抢': '已抢',
    '已抢光': '已抢光',
    '领取成功': '领取成功',
    //个人中心-我的优惠卷
    '我的优惠卷': '我的优惠卷',
    '未使用': '未使用',
    '已使用': '已使用',
    '已失效': '已失效',
    '使用规则': '使用规则',
    '立即使用': '立即使用',
    //个人中心——头部公共菜单
    '消息': '消息',
    '账户管理': '账户管理',

    //个人中心——左侧公共菜单
    '交易中心': '交易中心',
    '交易评价/晒单': '交易评价/晒单',
    '关注中心': '关注中心',
    '我的收藏': '我的收藏',
    '我的足迹': '我的足迹',
    '客户服务': '客户服务',
    '我的退款': '我的退款',
    '我的退货': '我的退货',
    '会员中心': '会员中心',
    '会员信息': '会员信息',
    '账号安全': '账号安全',
    '手机号管理': '手机号管理',
    '邮箱管理': '邮箱管理',
    '登录密码': '登录密码',
    '支付密码': '支付密码',
    '重置密码': '重置密码',
    '我的钱包': '我的钱包',
    '我的余额': '我的余额',
    '账户充值': '账户充值',
    '提现账号': '提现账号',
    '我的优惠券': '我的优惠券',


    //个人中心-我的余额
    '总金额': '总金额',
    '可用余额': '可用余额',
    '余额明细': '余额明细',
    '充值明细': '充值明细',
    '交易日期': '交易日期',
    '收入/支出': '收入/支出',
    '变动原因': '变动原因',
    '充值金额': '充值金额',


    //utils/common
    '请输入正确的手机号': '请输入正确的手机号',
    '密码最少6位哦～': '密码最少6位哦～',
    '密码最多20位哦～': '密码最多20位哦～',
    '密码不可以有中文哦～': '密码不可以有中文哦～',
    '密码中不可以有空格哦～': '密码中不可以有空格哦～',
    '请输入短信验证码': '请输入短信验证码',
    '请输入正确的短信验证码': '请输入正确的短信验证码',
    '请输入6～20位的会员名': '请输入6～20位的会员名',
    '请输入6～会员名不能全为数字': '会员名不能全为数字',
    '会员名须由中、英文、数字、"-"及"_"组成': '会员名须由中、英文、数字、"-"及"_"组成',
    '请输入正确的图形验证码': '请输入正确的图形验证码',
    '该功能在升级中～': '该功能在升级中～',
    '请输入邮箱': '请输入邮箱',
    '请输入正确的邮箱': '请输入正确的邮箱',

    // 消息设置
    '接收': '接收',
    '不接收': '不接收',

    //消息列表
    '标记已读': '标记已读',
    '确定删除这条消息吗？': '确定删除这条消息吗？',
    '查看详情 >': '查看详情 >',
    '暂时没有消息！': '暂时没有消息！',
    '请先选择要操作的消息！': '请先选择要操作的消息！',

    //品牌中心
    '品牌中心': '品牌中心',


    //充值页面
    '1.填写充值金额': '1.填写充值金额',
    '2.在线支付': '2.在线支付',
    '3.充值完成': '3.充值完成',
    '填写充值金额': '填写充值金额',
    '充值账户': '充值账户',
    '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账': '请注意：支持支付宝支付、微信支付，在线支付成功后，充值金额会在1到5分钟内到账',
    '温馨提示': '温馨提示',
    '下一步': '下一步',
    '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；': '1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；',
    '2.充值金额输入值必须是不小于1且不大于5000的正整数；': '2.充值金额输入值必须是不小于1且不大于5000的正整数；',
    '3.充值完成后，您可至会员中心查看充值记录。': '3.充值完成后，您可至会员中心查看充值记录。',
    '请使用手机微信扫描下方二维码进行支付': '请使用手机微信扫描下方二维码进行支付',
    '您正在充值余额，请尽快支付': '您正在充值余额，请尽快支付',
    '应付金额': '应付金额',
    '微信支付支付': '微信支付支付',
    '如二维码过期，': '如二维码过期，',
    '刷新': '刷新',
    '重新获取二维码。': '重新获取二维码。',
    '请输入充值金额': '请输入充值金额',
    '请选择支付方式': '请选择支付方式',
    "充值成功,2s后自动跳转充值列表": "充值成功,2s后自动跳转充值列表",
    '充值成功,2s后自动跳转支付页面': '充值成功,2s后自动跳转支付页面',
    '立即充值': '立即充值',
    '选择充值方式': '选择充值方式',
    '使用微信扫码支付': '使用微信扫码支付',
    '超过充值金额上限': '超过充值金额上限',

    //收货地址
    '地址管理': '地址管理',
    '默认地址': '默认地址',
    '新增地址': '新增地址',
    '全部地址': '全部地址',
    '暂无收货地址～': '暂无收货地址～',


    // 公共购物车顶部弹框组件
    '最新加入的商品': '最新加入的商品',
    '去购物车': '去购物车',

    //空页面组件
    '暂时没有数据～': '暂时没有数据～',

    //手机号管理
    '当前手机号': '当前手机号',
    '请输入新手机号': '请输入新手机号',
    '为了保障您的账号安全，变更重要信息需进行身份验证。': '为了保障您的账号安全，变更重要信息需进行身份验证。',
    '变更过程中有任何疑问请联系在线客服解决。': '变更过程中有任何疑问请联系在线客服解决。',
    '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。',

    //邮箱管理
    '请输入邮箱号': '请输入邮箱号',
    '请输入邮箱验证码': '请输入邮箱验证码',
    '请输入正确的邮箱验证码': '请输入正确的邮箱验证码',

    //设置登陆密码
    '复杂的密码可使账号更安全且建议定期更换密码。': '复杂的密码可使账号更安全且建议定期更换密码。',

    //个人中心--会员信息
    '仅支持JPG、GIF、PNG格式；文件大小请在1.0MB之内。': '仅支持JPG、GIF、PNG格式；文件大小请在1.0MB之内。',
    '会员名：': '会员名：',
    '性别：': '性别：',
    '保密': '保密',
    '男': '男',
    '女': '女',
    '昵称：': '昵称：',
    '生日：': '生日：',
    '年': '年',
    '月': '月',
    '日': '日',
    '保存': '保存',

    //收货地址
    '设置为默认': '设置为默认',
    '删除地址': '删除地址',
    '编辑地址': '编辑地址',

    //我的积分
    '日期': '日期',
    '详细说明': '详细说明',
    '可用积分': '可用积分',
    '收入': '收入',
    '支出': '支出',
    '修改密码': '修改密码',
    '请输入新密码': '请输入新密码',
    '该店铺已关闭': '该店铺已关闭',
    '待签约': '待签约',
    '执行中': '执行中',
    '已取消': '已取消',
};
