/*
 * @Author: your name
 * @Date: 2021-01-08 21:09:07
 * @LastEditTime: 2022-03-02 21:43:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './style/index.scss'
import "./assets/iconfont/iconfont.css";
import { get, post } from './utils/request'
import { chatUrl } from './utils/config'
import {
  getLocalStorageStr, getCurLanguage, goGoodsDetail, goStoreDetail, checkMobile, checkEmailCode, checkPwd, checkSmsCode,
  checkMemberName, checkImgCode, addCart, diyNavTo, sldCommonTip, checkEmail, getUserInfo, cartDataNum, getLoginCartListData, calculateSubtotal, goGoodsListByCatId, formatChatTime, isShowTime,quillEscapeToHtml
} from './utils/common';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import VueSocketIO from "vue-socket.io";

const app = createApp(App);

//组件引用 start，最后统一修改
// const components=[
//     ElButton,ElDialog
// ]
// components.forEach(component => {
//     app.component(component.name, component)
//   })
//组件引用 end
// 配置全局属性
app.config.globalProperties.$quillEscapeToHtml = quillEscapeToHtml;
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$getLocalStorageStr = getLocalStorageStr;
app.config.globalProperties.$getCurLanguage = getCurLanguage;
app.config.globalProperties.$goGoodsDetail = goGoodsDetail;
app.config.globalProperties.$goStoreDetail = goStoreDetail;
app.config.globalProperties.$checkMobile = checkMobile;
app.config.globalProperties.$checkPwd = checkPwd;
app.config.globalProperties.$checkSmsCode = checkSmsCode;
app.config.globalProperties.$checkMemberName = checkMemberName;
app.config.globalProperties.$checkImgCode = checkImgCode;
app.config.globalProperties.$addCart = addCart;
app.config.globalProperties.$diyNavTo = diyNavTo;
app.config.globalProperties.$sldCommonTip = sldCommonTip;
app.config.globalProperties.$checkEmail = checkEmail;
app.config.globalProperties.$getUserInfo = getUserInfo;
app.config.globalProperties.$cartDataNum = cartDataNum;
app.config.globalProperties.$getLoginCartListData = getLoginCartListData;
app.config.globalProperties.$calculateSubtotal = calculateSubtotal;
app.config.globalProperties.$checkEmailCode = checkEmailCode;
app.config.globalProperties.$goGoodsListByCatId = goGoodsListByCatId;
app.config.globalProperties.$formatChatTime = formatChatTime
app.config.globalProperties.$isShowTime = isShowTime
app.use(ElementPlus, { locale })
app.use(new VueSocketIO({
  debug: true,
  connection: chatUrl,
}))


app.use(router).use(store).mount('#app')




router.beforeEach(() => {
  window.scrollTo(0, 0)
})


